@font-face {
    font-family: 'FrutigerLTCom Bold';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/FrutigerLTCom-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'FrutigerLTCom Light';
    font-style: normal;
    font-weight: 200;
    src: url('./fonts/FrutigerLTCom-Light.ttf') format('truetype');
}
@font-face {
    font-family: 'FrutigerLTCom Roman';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/FrutigerLTCom-Roman.ttf') format('truetype');
}

/*Theme*/

html {
    --color-bg-default: #FFFFFF;

    --color-main-dark: #AD9072;
    --color-main-light: #EEE9E3;

    --color-text-default: #FFFFFF;
    --color-text-dark: #000000;
    --color-text-light: #6B7280;

    --color-radio-btn-default: #E8ECEF;
    --color-radio-btn-selected: #1A4564;
    --color-radio-btn-default-hover: #D3DADF;
    --color-radio-btn-selected-hover: #1e5a7e;

    --color-back-link-default: var(--color-text-light);
    --color-back-link-hover: #505765
}

body {
    font-family: 'FrutigerLTCom Roman';
    font-style: normal;
    margin: 0;
}

h1, h2, h3, h4, h5, h6, p, span, small {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0;
}

/*Scrollbar styles*/

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: var(--color-bg-default);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: var(--color-main-light);
    border-radius: 2px;
    cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: var(--color-main-dark);
}

.form-container {
    background-color: var(--color-bg-default);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: auto;
}

.step-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.step-header {
    height: 32px;
    width: 100%;
    background: var(--color-main-light);
    position: relative;
    margin-bottom: 120px;
}

.avatar-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.avatar {
    border-radius: 50%;
    overflow: hidden;
    margin-top: 8px;
}

.avatar img {
    display:block;
    width: 96px;
    height: 96px;
}

.avatar-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: var(--color-text-light);
    margin-top: 8px;
}

.step-content {
    margin: 0 16px;
    width: 100%;
    max-width: 916px;
    overflow: auto;
    height: 100%;
    position: relative;
}

.step-nav-container {
    width: 100%;
    margin-top: 24px;
}

.step-btn-container {
    display: flex;
    justify-content: center;
    position: relative;
}

@media (max-width: 359px) {
    .step-btn-container {
        flex-direction: column-reverse;
        align-items: center;
    }
}

.step-dot-container {
    height: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
}

.dot {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: visible;
    opacity: 1;
    transition: all 200ms ease-in-out;
}

.dot-hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 100ms ease-in-out;
}

.dot-current {
    background-color:  var(--color-main-light);
}

.dot-inner {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.dot-inner-default {
    background-color: var(--color-main-light);
}

.dot-inner-visited {
    background: var(--color-main-dark);
}

.title-container {
    max-width: 916px;
}

.title {
    font-family: 'FrutigerLTCom Bold';
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-align: center;
    color: var(--color-text-dark);
    margin: 24px;
}

.first-step-title {
    margin-top: 12px;
    margin-bottom: 0;
}

.sub-title {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: var(--color-main-dark);
}

.radio-btn-container {
    margin-top: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
    width: 100%;
    overflow: auto;
    left: 0;
}

.radio-btn {
    cursor: pointer;
    height: 34px;
    padding: 10px 13px 8px 33px;
    background: var(--color-radio-btn-default);
    border-radius: 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: var(--color-radio-btn-selected);
    margin: 12px 5px 0;
    box-sizing: border-box;
    display: inline-flex;
    position: relative;
}

.radio-btn:hover {
    background: var(--color-radio-btn-default-hover);
}

.selected {
    color: var(--color-text-default);
    background: var(--color-radio-btn-selected);
}

.selected:hover {
    color: var(--color-text-default);
    background: var(--color-radio-btn-selected-hover);
}

.radio-btn-icon {
    position: absolute;
    top: 0;
    left: 12px;
    width: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    cursor: pointer;
    padding: 9px 17px;
    background: var(--color-main-dark);
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    font-family: 'FrutigerLTCom Bold';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-text-default);
}

.btn:hover {
    text-decoration: underline;
}

.back-link-btn-icon {
    position: absolute;
    top: -1px;
    left: 0;
    width: 10px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.back-link-btn {
    position: absolute;
    top: 9px;
    left: 27px;
    cursor: pointer;
    padding-left: 21px;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-back-link-default);
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: unset;
    opacity: 1;
    transition: all 200ms ease-in-out;
}

@media (max-width: 359px) {
    .back-link-btn {
        top: 0;
        left: 0;
        margin-top: 12px;
        position: relative;
    }
}

.back-link-btn:hover {
    text-decoration: underline;
    color: var(--color-back-link-hover);
}

.back-link-btn-hidden {
    visibility: hidden;
    opacity: 0;
    transition: all 100ms ease-in-out;
}

.reduce-max-width {
    max-width: 608px;
}

@media (max-width: 768px) {

    .title-container {
        max-width: 720px;
    }

    .first-step-title-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: center;
        justify-items: center;
        justify-content: center;
    }

    .step-content {
        max-width: 720px;
    }
}

@media (max-width: 414px) {

    .step-nav-container {
        width: 100%;
    }

    .title-container {
        max-width: 382px;
    }

    .step-content {
        max-width: 371px;
    }

    .radio-btn {
        height: 30px;
        padding: 8px 11px 6px 29px;
        border-radius: 18px;
        font-size: 12px;
        line-height: 16px;
        margin: 8px 4px 0;
    }

    .radio-btn-icon {
        left: 10px;
    }
}
